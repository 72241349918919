





























































import useMisc from "@/use/misc";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  props: {
    currency: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(_, { root }) {
    const { goBack } = useMisc({ root });
    const state = reactive({
      expanded: [],
      defaultSort: "order",
      headers: [
        {
          value: "order",
          text: root.$tc("layout.misc.orderNumber"),
          sortable: false,
          width: 1,
        },
        {
          value: "name",
          text: root.$tc("layout.misc.name"),
          groupable: false,
          sortable: false,
        },
        {
          value: "state",
          text: root.$tc("layout.misc.status"),
          groupable: false,
          align: "end",
          sortable: false,
        },
      ],
      empty: false,
      items: [],
      loading: false,
      loaded: false,
      total: 0,
      options: {
        sortBy: ["order"],
        sortDesc: [false],
      },
      error: false as boolean | number,
    });

    // Start fetch registration fees

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const { sortBy, sortDesc } = (state.options as unknown) as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/registration-fee/group`, {
          params: { sortBy, sortDesc },
        })
        .then(({ data }) => {
          state.empty = false;
          state.items = data.map((group: any) => ({
            ...group,
            registrationFees: group.registrationFees
              .map((fee: any) => ({
                ...fee,
                registrationFeeTerms: fee.registrationFeeTerms.sort(
                  (a: any, b: any) => {
                    return b.order - a.order;
                  }
                ),
              }))
              .sort((a: any, b: any) => {
                return a.order - b.order;
              }),
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    // End fetch registration fees

    const registeredValue = (items: any) => {
      const sum = items.reduce((acc: any, obj: any) => {
        return acc + obj.participantRegistrationFeeTerms;
      }, 0);
      return sum;
    };

    const showParticipants = (id: string) => {
      goBack("panel.event.view.participant.search", { fee: id });
    };

    return { state, registeredValue, showParticipants };
  },
});
